import React from "react";

class Header extends React.Component {
	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark nav-custom">
				<div className="container">
					<a className="navbar-brand" href="/">
						Course Finder
					</a>
					<div className="" id="navmenu">
						<ul className="navbar-nav ms-auto">
							<li className="nav-item">
								<img
									className="logo"
									src="https://www.psb-academy.edu.sg/images/psb-logo-white.svg"
									alt="logo"
									srcset=""
								/>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Header;
