import React from "react";

class Banner extends React.Component {
	render() {
		return (
			<section className="bg-light text-dark p-5 landing-page">
				<div className="container h-100">
					<div className="d-flex align-items-center justify-content-between text-light h-100 ">
						<div className="lead my-5">
							<h1>READY TO DISCOVER THE RIGHT BACHELOR DEGREE FOR YOU?</h1>
							<p>
								Share a little bit about yourself and let this 15-question quiz
								help find the best bachelor degree for you.
							</p>
							<button className="btn-main" onClick={this.props.startQuiz}>
								Let's get started
							</button>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Banner;
