import React from "react";

const options = [
	{
		label: "Yes, that sounds like me",
		value: true,
		logo: `${process.env.PUBLIC_URL}/thumbs-up.jpg`,
	},
	{
		label: "No, that's not for me",
		value: false,
		logo: `${process.env.PUBLIC_URL}/thumbs-down.jpg`,
	},
];

class QuestionAndAnswer extends React.Component {
	render() {
		return (
			<div className="container-fluid">
				<section className="row align-items-center vh-100">
					<div className="col-md-6 col-sm-12">
						<div className="d-flex align-items-center">
							<h5 className="counter">{this.props.currentQuestion + 1}</h5>
							<h4 className="mt-4 mr-5 pr-5 fs-2">
								{this.props.questions[this.props.currentQuestion].questionText}
							</h4>
						</div>
						<p className="text-muted">{this.props.currentQuestion + 1}/ 15</p>
					</div>
					<div className="col-md-6 col-sm-12 align-items-center d-flex wrapper">
						<div className="d-grid gap-2 w-100">
							{options.map((answerOption) => (
								<button
									className="btn btn-outline-light text-start fs-3"
									type="button"
									onClick={() =>
										this.props.handleAnswerOptionClick(answerOption.value)
									}
								>
									<img
										className="answer-choice-icon"
										src={answerOption.logo}
										alt=""
									/>
									{answerOption.label}
								</button>
							))}
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default QuestionAndAnswer;
