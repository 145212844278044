import "./App.css";
import React from "react";
import Banner from "./components/Banner";
import CourseRecommendation from "./components/CourseRecommendation";
import Header from "./components/Header";
import HeaderBlack from "./components/HeaderBlack";
import QuestionAndAnswer from "./components/QuestionAndAnswer";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentQuestion: 0,
			currentPage: 0,
			isQuizStarted: false,
			questions: [
				{
					questionText: "I am interested in physical science.",
					recommendations: ["LS", "SES"],
					answer: false,
				},
				{
					questionText:
						"I am interested in designing systems to solve problems",
					recommendations: ["CS", "ENG", "IT"],
					answer: false,
				},
				{
					questionText: "I am interested in learning about living things.",
					recommendations: ["LS", "SES"],
					answer: false,
				},
				{
					questionText:
						"I am interested in strong verbal and written communication skills.",
					recommendations: ["BM", "EOH", "HOS", "LSC", "MAR", "MDC", "SES"],
					answer: false,
				},

				{
					questionText: "I am interested in analytic and systematic process.",
					recommendations: ["A&F", "EOH", "CM", "IT", "LSC"],
					answer: false,
				},
				{
					questionText:
						"I am interested in science and math, and I have mechanical aptitude.",
					recommendations: ["ENG"],
					answer: false,
				},
				{
					questionText:
						"I am interested in working with people, and I enjoy variety in my work.",
					recommendations: ["BM", "CS", "CM", "HOS", "LS", "MAR", "MDC", "SES"],
					answer: false,
				},
				{
					questionText:
						"I am interested in organizing and delegating responsibilities.",
					recommendations: ["BM", "CM", "EOH", "HOS", "LSC"],
					answer: false,
				},

				{
					questionText:
						"I am interested in analyzing, comparing, and interpreting data.",
					recommendations: ["A&F", "IT"],
					answer: false,
				},
				{
					questionText: "I am interested in numbers and procedures",
					recommendations: ["A&F"],
					answer: false,
				},
				{
					questionText: "I am interested in creative and design process.",
					recommendations: ["BM", "ENG", "CM", "IT", "MAR", "MDC"],
					answer: false,
				},

				{
					questionText: "I am interested in entrepreneurship",
					recommendations: ["A&F", "BM", "ENG"],
					answer: false,
				},

				{
					questionText:
						"I am interested in science and in the ability to think logically.",
					recommendations: ["CS", "ENG", "LS"],
					answer: false,
				},

				{
					questionText:
						"I am interested in science, enjoy helping people, and have very strong morals.",
					recommendations: ["EOH", "LS", "SES"],
					answer: false,
				},

				{
					questionText: "I am interested in people management",
					recommendations: ["BM", "CM", "EOH", "HOS", "LSC"],
					answer: false,
				},
			],
			courseRecommendations: [],
		};

		this.startQuiz = this.startQuiz.bind(this);
		this.handleAnswerOptionClick = this.handleAnswerOptionClick.bind(this);
	}

	setCurrentQuestion(curQuestion) {
		this.setState({ currentQuestion: curQuestion });
	}

	onUpdateItem(i, isCorrect) {
		this.setState((state) => {
			const list = state.questions.map((item, j) => {
				if (j === i) {
					item.answer = isCorrect;
					return item;
				} else {
					return item;
				}
			});

			return {
				list,
			};
		});
	}

	startQuiz() {
		this.setState({ currentPage: 1 });
	}

	handleAnswerOptionClick(isCorrect) {
		this.onUpdateItem(this.state.currentQuestion, isCorrect);

		const nextQuestion = this.state.currentQuestion + 1;

		if (nextQuestion < this.state.questions.length) {
			this.setCurrentQuestion(nextQuestion);
		} else {
			this.recommendCourses();
			this.setState({ currentPage: 2 });
		}
	}

	recommendCourses() {
		const courseRecommendations = this.state.questions
			.filter((question) => question.answer)
			.map((question) => question.recommendations)
			.flat()
			.reduce((byKey, item) => {
				if (!byKey[item]) byKey[item] = 0;
				byKey[item]++;
				return byKey;
			}, {});

		let sortedCourses = Object.keys(courseRecommendations).sort(function (
			a,
			b
		) {
			return courseRecommendations[b] - courseRecommendations[a];
		});

		if (sortedCourses.length > 3) {
			sortedCourses = sortedCourses.slice(0, 3);
		}

		this.setState({ courseRecommendations: sortedCourses });
	}

	render() {
		return (
			<div className="app">
				{this.state.currentPage === 0 ? <Header/>: <HeaderBlack/>}
				{/* <Header /> */}
				{this.state.currentPage === 0 && <Banner startQuiz={this.startQuiz} />}
				{this.state.currentPage === 1 && (
					<QuestionAndAnswer
						questions={this.state.questions}
						currentQuestion={this.state.currentQuestion}
						handleAnswerOptionClick={this.handleAnswerOptionClick}
					/>
				)}
				{this.state.currentPage === 2 && (
					<CourseRecommendation
						courseRecommendations={this.state.courseRecommendations}
					/>
				)}
			</div>
		);
	}
}

export default App;
