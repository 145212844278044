import React from "react";
import RedirectList from "../RedirectList.json";

class CourseRecommendation extends React.Component {
	findCourseObjectByCode(code) {
		return RedirectList.find((redirect) => redirect.code === code);
	}

	render() {
		return (
			<>
				<section className="container recommendation-section">
					<div className="heading p-4">
						<h1 className="fs-2">
							We've found three bachelor's degrees that match your interests.
						</h1>
					</div>
					<div className="row mt-4">
						{this.props.courseRecommendations.map((course) => (
							<div className="col-md-4 pt-1 ">
								<div className="d-flex flex-column justify-content-between border h-100 p-2">
									<div className="d-flex flex-column">
										<h3 className="fs-4 fw-bold">
											{" "}
											{"Bachelors in "}
											{this.findCourseObjectByCode(course).courseName}
										</h3>
										<p className="text-justify">{this.findCourseObjectByCode(course).courseDescription}</p>
									</div>
							
									<a
										href={
											"https://api.whatsapp.com/send?phone=" +
											this.findCourseObjectByCode(course).contactPerson
												.phoneNumber +
											"&text=Hi " +
											this.findCourseObjectByCode(course).contactPerson.name +
											", I am interested in " +
											this.findCourseObjectByCode(course).courseName +
											" / " +
											this.findCourseObjectByCode(course).destination +
											" Thanks"
										}
										className="btn btn-outline-dark"
									>
										More Info
									</a>
								</div>
							</div>
						))}
					</div>
				</section>

				<section className="container enquire-section">
					<div className="lastline pt-4">
						<h4 className="fs-5">These results are based on the answers you provided. If you'd like to receive more information or a complete guide to our bachelor's degrees, don’t hesitate to get in touch <a  href="https://www.psb-academy.edu.sg/academic-levels/bachelors-degree#enquire-now" className="enquire-now"> {'ENQUIRE NOW'} </a></h4>
					</div>
				</section>
			</>
		);
	}
}

export default CourseRecommendation;
